import React from "react";
import { Box, Typography, Button, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";

const QuizSubmitted: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#1a2a6c", // ✅ Matches quiz styling
        color: "white",
        textAlign: "center",
        px: 4,
      }}
    >
      <Stack spacing={3} alignItems="center">
        <Typography variant="h4" fontWeight="bold">
          🎉 Thank You for Completing the Quiz and your interest to join!
        </Typography>
        <Typography variant="body1">
          Your responses have been recorded. Stay tuned for updates!
        </Typography>
        <Button
          variant="contained"
          sx={{ backgroundColor: "white", color: "#1a2a6c", fontWeight: "bold" }}
          onClick={() => navigate("/competition")}
        >
          Go to Home
        </Button>
      </Stack>
    </Box>
  );
};

export default QuizSubmitted;