// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getFunctions, httpsCallable, connectFunctionsEmulator } from "firebase/functions";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBKwhWc1MnQ0qiv9UpoAamprlEl65--QYs",
  authDomain: "tatobay-prod.firebaseapp.com",
  projectId: "tatobay-prod",
  storageBucket: "tatobay-prod.firebasestorage.app",
  messagingSenderId: "915122665926",
  appId: "1:915122665926:web:a17347d1c9874603a41ac7",
  measurementId: "G-Q8FVHVVZKF"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const analytics = getAnalytics(app);
const functions = getFunctions(app);
export {app, db, functions, httpsCallable}
