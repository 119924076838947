import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";


import { CssBaseline } from "@mui/material";
import Home from "./components/Home";
import QuizHome from "./components/QuizHome";
import Quiz from "./components/Quiz";
import QuizSubmitted from "./components/QuizSubmitted";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1976D2", // ✅ Custom Blue
    },
    secondary: {
      main: "#ff9800", // ✅ Custom Orange
    },
  },
  components: {
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: "1.8rem", // ✅ Larger Text for All List Items
          fontWeight: "normal", // ✅ Bold
        },
      },
    },
  },
});


const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline /> {/* ✅ Ensures a consistent Material UI look */}
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/competition" element={<QuizHome />} />
          <Route path="/quiz" element={<Quiz />} />
          <Route path="/thank-you" element={<QuizSubmitted />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;