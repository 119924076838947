import React from "react";
import { Box, Typography, Stack } from "@mui/material";
import { ReactComponent as Logo } from "../logo.svg"; // ✅ Import SVG Logo

const ComingSoon: React.FC = () => {
  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#1a2a6c", // ✅ Navy Blue Background
        color: "white",
        textAlign: "center",
        px: 4,
      }}
    >
      <Stack spacing={3} alignItems="center">
        {/* ✅ App Logo */}
        <Logo width={120} height={120} />

        {/* ✅ Coming Soon Message */}
        <Typography variant="h4" fontWeight="bold">
          🚀 Coming Soon!
        </Typography>
        <Typography variant="body1" sx={{ maxWidth: 500 }}>
          We're building something amazing. Stay tuned for the launch!
        </Typography>
      </Stack>
    </Box>
  );
};

export default ComingSoon;