

import React, { useEffect, useState } from "react";
// import { collection, getDocs } from "firebase/firestore";
// import { db } from "../firebaseConfig";
import { useLocation, useNavigate } from "react-router-dom";
import QuizQuestion from "./QuizQuestion";
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from '@google-recaptcha/react';
import { functions, httpsCallable } from "../firebaseConfig";
import { Backdrop, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Button } from "@mui/material";

interface Question {
    id: string;
    question: string;
    options: string[];
}
interface GetRandomQuestionsRequest { }
interface GetRandomQuestionsResponse {
    success: boolean;
    questions: Question[];
}

const RECAPTCHA_SITE_KEY = "6LeSbeEqAAAAABNIyeWB6lQqa-QYMVOx7f_qRKPC";
const submitQuizFunction = httpsCallable(functions, "submitQuiz"); // ✅ Firebase Function
const getRandomQuestionsFunction = httpsCallable<
    GetRandomQuestionsRequest,
    GetRandomQuestionsResponse
>(functions, "getRandomQuestions");


const Quiz: React.FC = () => {
    const { executeV3 } = useGoogleReCaptcha();
    const [questions, setQuestions] = useState<Question[]>([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    // const [answers, setAnswers] = useState<{ questionId: string; answer: string }[]>([]);
    const [answers, setAnswers] = useState<Record<number, { questionId: string; answer: string }>>({});
    const [loading, setLoading] = useState(true);
    const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false); // ✅ Track submission state
    const [errorDialogOpen, setErrorDialogOpen] = useState(false); // ✅ Track error dialog state

    const location = useLocation();
    const navigate = useNavigate();

    const { firstName, lastName, email } = location.state || {}; // 

    // Fetch questions from Firestore ("quiz_questions")
    useEffect(() => {
        const fetchQuestions = async () => {
            try {
                const response = await getRandomQuestionsFunction({});
                // Expected response shape: { data: { success: boolean, questions: [...] } }

                if (response.data && response.data.success) {
                    const fetchedQuestions = response.data.questions as Question[];
                    setQuestions(fetchedQuestions);
                } else {
                    setErrorMessage("Failed to fetch questions from server.");
                }
            } catch (error) {
                console.error("Error fetching questions:", error);
                setErrorMessage("An error occurred while fetching questions.");
            } finally {
                setLoading(false);
            }
        };

        fetchQuestions();
    }, []);

    useEffect(() => {
        if (questions.length > 0) {
            if (currentQuestionIndex < questions.length - 1) {
                setCurrentQuestionIndex((prev) => prev + 1);
                // setTimeout(() => {
                //     setCurrentQuestionIndex((prev) => prev + 1);
                // }, 600);
            } else {
                submitQuiz();
                // setTimeout(() => {
                //     submitQuiz();
                // }, 300);
            }
        }
    }, [answers]);

    useEffect(() => {
        console.log("currentQuestionIndex:" + currentQuestionIndex);
    }, [currentQuestionIndex]);

    useEffect(() => {
        setTimeout(() => {
            (document.activeElement as HTMLElement | null)?.blur();
            window.getSelection()?.removeAllRanges();
        }, 0);
      }, [currentQuestionIndex]);

    const handleSelectAnswer = (answer: string) => {
        setAnswers((prevAnswers) => {
            const updatedAnswers = { ...prevAnswers };
            updatedAnswers[currentQuestionIndex] = {
                questionId: questions[currentQuestionIndex].id,
                answer: answer,
            };
            return updatedAnswers;
        });
    };

    const submitQuiz = async () => {
        const finalAnswers = Object.keys(answers)
            .sort((a, b) => Number(a) - Number(b))
            .map((key) => answers[Number(key)]);


        console.log("questions:", questions);
        console.log("Submitting quiz:", finalAnswers);

        if (!executeV3) {
            console.warn("reCAPTCHA not ready");
            setErrorMessage("reCAPTCHA is not available. Please try again.");
            return;
        }

        setIsSubmitting(true);

        try {
            const recaptchaToken = await executeV3("submit_quiz");
            const response = await submitQuizFunction({
                firstName,
                lastName,
                email,
                answers: finalAnswers,
                recaptchaToken,
            });

            console.log("✅ Quiz submitted successfully!", response.data);
            navigate("/thank-you");
        } catch (error: any) {
            console.error("❌ Error submitting quiz:", error.message);
            setErrorMessage("Failed to submit quiz. Please try again.");
            setErrorDialogOpen(true);
        } finally {
            setIsSubmitting(false);
        }
    };

    const onBack = () => {
        if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex((prev) => prev - 1)
        }
    }

    return loading ? (
        <div>Loading...</div>
    ) : (
        <div>
            <Backdrop open={isSubmitting} sx={{ color: "#fff", zIndex: 1300 }}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <Dialog open={errorDialogOpen} onClose={() => setErrorDialogOpen(false)}>
                <DialogTitle>Error</DialogTitle>
                <DialogContent>
                    <p>{errorMessage}</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setErrorDialogOpen(false)} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={() => { setErrorDialogOpen(false); submitQuiz(); }} color="primary" variant="contained">
                        Retry
                    </Button>
                </DialogActions>
            </Dialog>

            {questions[currentQuestionIndex] && (
                <QuizQuestion
                    key={`${questions[currentQuestionIndex].id}-${Math.random()}`} // ✅ Forces a fresh instance on question change
                    question={questions[currentQuestionIndex].question}
                    options={questions[currentQuestionIndex].options}
                    onSelectAnswer={handleSelectAnswer}
                    onBack={() => onBack()}
                    progress={(currentQuestionIndex / questions.length) * 100}
                    currentQuestionIndex={
                        currentQuestionIndex
                    }
                />
            )}
        </div>
    );
};


// ✅ Wrap the Quiz Component with GoogleReCaptchaProvider
const QuizWithRecaptcha: React.FC = () => (
    <GoogleReCaptchaProvider siteKey={RECAPTCHA_SITE_KEY} type="v3" >
        <Quiz />
    </GoogleReCaptchaProvider>
);

export default QuizWithRecaptcha;