import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Dialog, DialogTitle, DialogContent, TextField, Button, Box, Typography, Stack } from "@mui/material";
import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle"; // ✅ Custom Bullet
import ataLogo from "./ata_logo_light.png"; // Corrected import for PNG file
import { ReactComponent as Logo } from "../logo.svg"; // ✅ Import SVG Logo


const QuizHome: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const handleStartQuiz = () => {
    if (firstName && lastName && email) {
      navigate("/quiz", { state: { firstName, lastName, email } });
    } else {
      alert("Please fill in all fields");
    }
  };

  return (
    <Box
      sx={{
      height: "auto",
      width: "auto",
      minHeight: "100vh",
      display: "flex",
      justifyContent: "center", // Center horizontally
      alignItems: "center", // Center vertically
      backgroundColor: "#1a2a6c",
      color: "white",
      textAlign: "center",
      }}
    >
      <Stack spacing={4} alignItems="center">
      <Box
        component="img"
        src={ataLogo} // Use imported image
        alt="ATA Logo"
        sx={{ width: 150, height: "auto" }}
      />

      <Typography variant="h3" fontWeight="bold">
        Afghan Tech Association
      </Typography>
      <Typography variant="h4" fontWeight="bold">
        Software Competition
      </Typography>

      <List>
        <ListItem>
      <ListItemIcon>
        <CircleIcon fontSize="medium" color="secondary" /> {/* Custom bullet */}
      </ListItemIcon>
      <ListItemText primary="Showcase your skills" />
        </ListItem>
        <ListItem>
      <ListItemIcon>
        <CircleIcon fontSize="medium" color="secondary" /> {/* Custom bullet */}
      </ListItemIcon>
      <ListItemText primary="Solve a real world challenge" />
        </ListItem>
        <ListItem>
            <ListItemIcon>
        <CircleIcon fontSize="medium" color="secondary" /> {/* Custom bullet */}
            </ListItemIcon>
            <ListItemText
        primary={
          <Typography variant="h4" fontWeight="bold" color="secondary">
            Win a prize up to <span style={{ fontSize: "3rem" }}>100000 AFN</span>
          </Typography>
        }
            />
        </ListItem>
      </List>

      <Typography variant="h5" fontWeight="bold">
        Spots are limited.
      </Typography>
      <Typography variant="h5" fontWeight="bold">
        Join the waiting list today by completing a short quiz!
      </Typography>

      <Button
        variant="contained"
        onClick={() => setOpen(true)}
        sx={{
      fontSize: "1.5rem", // ✅ Bigger Text
      padding: "16px 32px", // ✅ Bigger Button
      minWidth: "250px", // ✅ Ensures Width
      minHeight: "60px", // ✅ Ensures Height
        }}
      >
        Join the waiting list now!
      </Button>
      </Stack>

      {/* ✅ Popup Modal for User Details */}
      <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Enter your details below to start the quiz</DialogTitle>
      <DialogContent>
        <Stack spacing={2} mt={2}>
        <Stack direction="row" spacing={2}>
          <TextField label="First name *" fullWidth value={firstName} onChange={(e) => setFirstName(e.target.value)} />
          <TextField label="Last name *" fullWidth value={lastName} onChange={(e) => setLastName(e.target.value)} />
        </Stack>
        <TextField label="Email *" fullWidth value={email} onChange={(e) => setEmail(e.target.value)} />
        <Button variant="contained" onClick={handleStartQuiz}>
          Start
        </Button>
        </Stack>
      </DialogContent>
      </Dialog>
    </Box>
  );
};

export default QuizHome;