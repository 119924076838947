import React from "react";
import { Box, Typography, IconButton, LinearProgress, Card, CardActionArea, Stack, Button } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"; // ✅ Back Button with Icon & Text

interface QuizQuestionProps {
  question: string;
  options: string[];
  onSelectAnswer: (answer: string) => void;
  onBack: () => void;
  progress: number;
  currentQuestionIndex: number;
}

const QuizQuestion: React.FC<QuizQuestionProps> = ({
  question,
  options,
  onSelectAnswer,
  onBack,
  progress,
  currentQuestionIndex
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        px: 4,
        maxWidth: 600,
        mx: "auto",
        position: "relative",
      }}
    >
      {/* ✅ Progress Bar at the Top */}
      <Box sx={{ position: "absolute", top: 20, left: 0, right: 0, textAlign: "center", px: 3 }}>
        <Typography variant="body1" fontWeight="bold">
          {Math.round(progress)}% Complete
        </Typography>
        <LinearProgress
          variant="determinate"
          value={progress}
          sx={{ mt: 1, height: 8, borderRadius: 5, width: "100%" }}
        />
      </Box>

      {/* ✅ Back Button (Now Closer to Question) */}
      <IconButton onClick={onBack} sx={{ alignSelf: "flex-start", mb: 2 }}>
        <ChevronLeftIcon fontSize="medium" sx={{ color: "#222" }} />
        <Typography variant="body1" sx={{ fontSize: "16px", fontWeight: "bold", ml: 1 }}>
          BACK
        </Typography>
      </IconButton>

      {/* ✅ Question (Centered) */}
      <Typography
        variant="h5"
        sx={{
          fontFamily: "Google Sans",
          fontWeight: 600,
          mb: 3,
          textAlign: "center",
        }}
      >
        {question}
      </Typography>

      {options.length === 2 && options.includes("Yes") && options.includes("No") ? (
        <Stack direction="row" spacing={2}>
          {options.map((option, index) => (
            <Button
              key={`${currentQuestionIndex}-${index}`}
              variant="contained"
              color="primary"
              onClick={() => {
                onSelectAnswer(option);
                (document.activeElement as HTMLElement | null)?.blur(); // ✅ Removes focus to reset hover
              }} sx={{
                minWidth: 120,
                textTransform: "none",
                fontSize: "16px",
              }}
            >
              {option}
            </Button>
          ))}
        </Stack>
      ) : (
        /* ✅ Otherwise, Use FLAT, NAVY BLUE Cards */
        <Stack spacing={2} sx={{ width: "100%" }}>
          {options.map((option, index) => (
            <Card
              key={`${currentQuestionIndex}-${index}`}
              onClick={() => {
                // ✅ Remove focus & hover state manually
                setTimeout(() => {
                  (document.activeElement as HTMLElement | null)?.blur();
                  window.getSelection()?.removeAllRanges(); // ✅ Clears selection (for iOS)
                }, 0);
                onSelectAnswer(option);
                // ✅ Removes focus to reset hover
              }}
              sx={{
                p: 2,
                borderRadius: 3, // ✅ Rounded corners
                backgroundColor: "#ffffff", // ✅ Navy Blue
                color: "#16245d",
                cursor: "pointer",
                transition: "background 0.2s",
                // "&:hover": { background: "#1976d2", color: "white" }, // ✅ Slight hover effect
                "&:active": { background: "#1976d2", color: "white" },
                border: "1px solid #d0d0d0",
                boxShadow: "none",
                userSelect: "none",
              }}
            >
              <CardActionArea sx={{ p: 2 }}>
                <Typography variant="body1" sx={{ textAlign: "center", fontSize: "16px" }}>
                  {option}
                </Typography>
              </CardActionArea>
            </Card>
          ))}
        </Stack>
      )}
    </Box>
  );
};

export default QuizQuestion;